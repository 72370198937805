<template>
  <div>
    <span v-if="data.linkedin_profile">{{ data.linkedin_profile.name }}</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  }
}
</script>
