<template>
  <div
    class="e-card"
    style="display:flex;flex-direction:row"
    :class="{'inactive': !result.data.is_active }"
    >
    <div style="flex:1">
      <div class="e-card-header">
        <a-avatar style="backgroundColor:#87d068" size="large" icon="user" />
      </div>
    </div>
    <div style="flex:5">
         <div class="e-card-header">
             <div class="e-card-header-caption">
                 <div v-if="$auth.user.profile.role == 'admin'" class="e-card-header-title">
                   <a-dropdown>

                       <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                        {{ result.data.name }}
                       </a>
                       <a-menu slot="overlay">
                         <a-menu-item>
                           <a target="_blank" :href="result.data.link">Open {{result.source}}</a>
                         </a-menu-item>
                         <a-menu-divider />
                         <a-menu-item>
                           <a href="javascript:;" @click="setRegisterResultView('confirm',result)">Confirm</a>
                         </a-menu-item>
                         <a-menu-item>
                           <a href="javascript:;" @click="setRegisterResultView('hidden', result)">Hide</a>
                         </a-menu-item>
                       </a-menu>
                     </a-dropdown>
                 </div>
                 <div v-else>
                   <a target="_blank" :href="result.data.link">{{ result.data.name }}</a>
                 </div>
                 <div class="e-card-sub-title">{{ result.data.country }} | {{ result.source }} | {{ result.reference }} </div>
                 <div class="e-card-sub-title" v-for="status in result.data.status">{{ status }}</div>
                 <a-tag color="red" v-if="!result.data.is_active">Inactive</a-tag>
             </div>
         </div>

        <div class="e-card-actions">

        </div>
  </div>
  <div v style="flex:10;padding-top:20px" >
    <a-timeline>
      <a-timeline-item v-for="firm in result.data.firms">
        <a-icon slot="dot" v-if="firm.is_name_match" type="check" style="color:#2dca5e;font-size: 20px;" />
        <div class="employer" >
          {{ firm.name }}
        </div>
        <div style="font-size:.8em;" v-if="firm.closests_address">
          {{ firm.closests_address.address }}
          <div>
            {{ firm.closests_address.distance_km }} km
          </div>
        </div>
      </a-timeline-item>
    </a-timeline>
  </div>
  </div>
</template>
<style>
  .strong {
    color:black;
    font-weight:500;
    font-size:13px;
  }

  .industry {
    font-style: italic;
  }

  .inactive {
    background-color: #f3f3f3;
  }

</style>
<script>
const kysapi = require('../kysapi')

export default {
  name: 'RegisterResultCard',
  data: ()=>{
    return {

    }
  },
  props: {
    result: Object
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
    setRegisterResultView: async function(view) {
      this.$emit('updateView', view)
    }
  }
}
</script>
