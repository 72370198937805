<template>
  <div
    class="e-card"
    style="display:flex;flex-direction:row">
    <div style="flex:1">
      <div class="e-card-header">
        <a-avatar style="backgroundColor:#6777ec" size="large" icon="bank">
        </a-avatar>
      </div>
      <div class="e-card-header">
        {{ result.name_score }}
      </div>

    </div>
    <div style="flex:5">
         <div class="e-card-header">

             <div class="e-card-header-caption">
                 <div v-if="$auth.user.profile.role == 'admin'" class="e-card-header-title">
                   <a-dropdown>
                       <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                        {{ result.name }}
                       </a>
                       <a-menu slot="overlay">
                         <a-menu-item>
                           <a target="_blank" :href="result.link">Open {{result.source}}</a>
                         </a-menu-item>
                         <a-menu-divider />
                         <a-menu-item>
                           <a href="javascript:;" @click="setRegisterResultView('confirm',result)">Confirm</a>
                         </a-menu-item>
                         <a-menu-item>
                           <a href="javascript:;" @click="setRegisterResultView('hidden', result)">Hide</a>
                         </a-menu-item>
                       </a-menu>
                     </a-dropdown>
                 </div>
                 <div v-else>
                   <a target="_blank" :href="result.link">{{ result.name }}</a>
                 </div>
                 <div class="e-card-sub-title">{{ result.country }} | {{ result.source }} | {{ result.reference || result.reference_id}} </div>
                 <div class="e-card-sub-title" >{{ result.status }}</div>
             </div>
         </div>

        <div class="e-card-actions">

        </div>
  </div>
  <div v style="flex:10;padding-top:20px" >
  </div>
  </div>
</template>
<style>
  .strong {
    color:black;
    font-weight:500;
    font-size:13px;
  }

  .industry {
    font-style: italic;
  }

  .inactive {
    background-color: #f3f3f3;
  }

</style>
<script>
const kysapi = require('../kysapi')

export default {
  name: 'EmployerResultCard',
  data: ()=>{
    return {

    }
  },
  props: {
    result: Object
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
    setRegisterResultView: async function(view) {
      this.$emit('updateView', view)
    }
  }
}
</script>
