<template>
  <div>
    <a @click="selectName(data)" href="javascript:void(0);">{{ data.name }}</a>
    <a-icon v-if="data.linkedin" theme="filled" style="padding-left:10px;font-size:15px;color:#0b6cff" type="linkedin" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
  methods: {
    selectName: function(data){
      this.$eventHub.$emit('subscriberSelected',data);
    }
  }
}
</script>
