export default {
  // main navigation - side menu
  columns: [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter:  (a, b) => { return a.name.localeCompare(b.name)},
      key: 'name',
      scopedSlots: { customRender: 'name' },
//      fixed: 'left'
    },
    {
      title: 'LinkedIn',
      dataIndex: 'linkedin_profile',
      key: 'linkedin',
      scopedSlots: { customRender: 'linkedin' },
    },
    {
      title: 'Industry',
      dataIndex: 'linkedin_profile',
      key: 'linkedin_industry',
      scopedSlots: { customRender: 'linkedin_industry' },
    },
    {
      title: 'Employer',
      dataIndex: 'employer',
      key: 'employer',
      scopedSlots: { customRender: 'employer' },
    },

    {
      title: 'Country',
      dataIndex: 'country',
      scopedSlots: { customRender: 'country' },
    },
    {
      title: 'Usecases',
      dataIndex: 'usecases',
      scopedSlots: { customRender: 'usecases' },
//      fixed: 'right'
    },
  ]
}
