<template>
  <div>
    <div>{{ data.employer_name }}</div>
    <div v-for="employment_title in data.employment_titles">
    {{ employment_title }}
    </div>
    <div>{{ data.employment_title }}</div>
    <div>{{ data.employment_status }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  }
}
</script>
