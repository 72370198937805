<template>
  <div v-if="data.usecases">
      <div v-for="uc in data.usecases">{{uc}}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  }
}
</script>
