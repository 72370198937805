<template>
    <div style="padding-top:30px" >
      <h1>Recent Files</h1>
      <a-card style="max-width:1080px">
        <a-row>
          <a-col>
            <a-list
              class="demo-loadmore-list"
              item-layout="horizontal"
              :data-source="files"
              size="small"
            >
              <a-list-item slot="renderItem" slot-scope="item, index">
                <a-list-item-meta>
                  <a slot="title" @click="download_file(item)">{{ item.name }}</a>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </a-col>
        </a-row>
      </a-card>
    </div>
</template>
<style>
  .strong {
    color:black;
    font-weight:500;
    font-size:13px;
  }

  .industry {
    font-style: italic;
  }
</style>
<script>

export default {
  name: 'FileViewer',
  components: {
  },
  data: ()=>{
    return {
    }
  },
  props: {
    files: Array
  },
  methods: {
    download_file: async function(file){
      console.log(file)
      console.log('api/file/' + file.key)
      const response = await this.$kysapi.get('api/file/' + file.key, this.$auth, {responseType: "blob"})
      console.log(response)
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = file.name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  }
}
</script>
