<template>
  <div v-if="form.kysfirm">
    <a-form :layout="form.layout">
      <a-form-item label="Names"
         :label-col="form.itemLayout.labelCol"
         :wrapper-col="form.itemLayout.wrapperCol"
      >
      <div v-for="name in form.kysfirm.names">
        {{ name }}
        <a-tooltip>
          <template slot="title">
            delete
          </template>
          <a href="javascript:void(0)"><a-icon type="close"/></a>
        </a-tooltip>
      </div>
      <a-button size="small">Add</a-button>
      </a-form-item>
       <a-form-item label="Type"
          :label-col="form.itemLayout.labelCol"
          :wrapper-col="form.itemLayout.wrapperCol"
       >

         <a-select default-value="Unknown" v-model="form.kysfirm.company_type">
           <a-select-option value="Unknown">
             None
           </a-select-option>
           <a-select-option value="Registered">
             Registered
           </a-select-option>
           <a-select-option value="Financial">
             Financial
           </a-select-option>
           <a-select-option value="TradingInstitution">
             Learning/Trading Institution
           </a-select-option>
           <a-select-option value="NonFinancial">
             Non Financial
           </a-select-option>
         </a-select>
       </a-form-item>
       <a-form-item label="Scope"
          :label-col="form.itemLayout.labelCol"
          :wrapper-col="form.itemLayout.wrapperCol"
       >
         <a-select default-value="GLOBAL" v-model="form.kysfirm.scope">
           <a-select-option value="">
             None
           </a-select-option>
           <a-select-option value="SUBSCRIBER">
             Subscriber
           </a-select-option>
           <a-select-option value="NAMELIST">
             Namelist
           </a-select-option>
           <a-select-option value="COUNTRY">
             Country
           </a-select-option>
           <a-select-option value="GLOBAL">
             ALL / Global
           </a-select-option>
         </a-select>
       </a-form-item>
       <a-form-item v-if="form.kysfirm.scope && form.kysfirm.scope.length > 1 && form.kysfirm.scope != 'GLOBAL'" :label="form.kysfirm.scope"
         :label-col="form.itemLayout.labelCol"
         :wrapper-col="form.itemLayout.wrapperCol"
         >
        <a-input v-model="form.kysfirm.scope_value" placeholder="scope_value" />
       </a-form-item>
       <a-form-item label="Description"
         :label-col="form.itemLayout.labelCol"
         :wrapper-col="form.itemLayout.wrapperCol"
       >
         <a-textarea v-model="form.kysfirm.description" placeholder="" :rows="4" />
       </a-form-item>
       <a-form-item label="Link1"
         :label-col="form.itemLayout.labelCol"
         :wrapper-col="form.itemLayout.wrapperCol"
         >
        <a-input v-model="form.kysfirm.link1" placeholder="https://" />
       </a-form-item>
       <a-form-item label="Link2"
         :label-col="form.itemLayout.labelCol"
         :wrapper-col="form.itemLayout.wrapperCol"
         >
        <a-input v-model="form.kysfirm.link2" placeholder="https://" />
       </a-form-item>
       <a-form-item label="Link3"
         :label-col="form.itemLayout.labelCol"
         :wrapper-col="form.itemLayout.wrapperCol"
         >
        <a-input v-model="form.kysfirm.link3" placeholder="https://" />
       </a-form-item>
       <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
          <a-button type="primary" html-type="submit" @click="submit">
            Update
          </a-button>
        </a-form-item>
     </a-form>
  </div>
</template>
<style>
  .strong {
    color:black;
    font-weight:500;
    font-size:13px;
  }

  .industry {
    font-style: italic;
  }
</style>
<script>

export default {
  name: 'KysFirmEditor',
  data: ()=>{
    return {
      form: {
        kysfirm: {
          name: null,
          company_type: null,
          scope: null,
          scope_value: null,
          description: null,
          link1: null,
          link2: null,
          link3: null
        },
        layout: 'horizontal',
        itemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
      },
    }
  },
  props: {
    kysfirm: Object
  },
  computed: {
  },
  mounted: function() {
    this.form.kysfirm = Object.assign( {}, this.kysfirm )
  },
  methods: {
    submit: async function() {
      console.log(this.form)
    }
  }
}
</script>
