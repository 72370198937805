<template>
  <div>
    <div v-for="kysfirm in matches" :key="kysfirm.id">
      <a-tag color="green" >
          {{ kysfirm.company_type }}
      </a-tag>
      <a-tag color="#2db7f5" v-if="kysfirm.scope_value" >
          {{ kysfirm.scope_value }}
      </a-tag>
    </div>
  </div>
</template>
<style>
  .strong {
    color:black;
    font-weight:500;
    font-size:13px;
  }

  .industry {
    font-style: italic;
  }
</style>
<script>

export default {
  name: 'KysFirmInfo',
  data: ()=>{
    return {
    }
  },
  props: {
    kysfirms: Array,
    name: String
  },
  computed: {
    matches: function () {
      let firms = []
      const self = this;
      if (this.kysfirms && self.name) {
        this.kysfirms.forEach((kysfirm, i) => {
          if(kysfirm.names.includes(self.name.toUpperCase())) {
            firms.push(kysfirm)
          }
        });
      }

      return firms
    }
  },
  mounted: function() {
  },
  methods: {
    search: async function() {
      console.log(this)
      let query = new URLSearchParams({
        matching: 'similar'
      })
      if (this.searchparams.id && this.searchparams.id.length > 0) {
        query.append('id', this.searchparams.id)
      }
      if (this.searchparams.name && this.searchparams.name.length > 0) {
        query.append('name', this.searchparams.name)
      }
      var response = await this.$kysapi.get('api/kysfirms'  + "?" + query.toString(), this.$auth)
      console.log(response)
    },
    create: async function(){
      let kysfirm = Object.assign( {}, this.form.kysfirm )
      if(kysfirm.scope === 'Country'){
        kysfirm.scope_value = this.subscriber.country
      }
      else if(kysfirm.scope === 'Namelist'){
        this.kys_firm.scope_value = this.namelist.name
      }
      else if(kysfirm.scope === 'Subscriber'){
        kysfirm.scope_value = this.subscriber.id
      }
      console.log(kysfirm)
      var response = await kysapi.post('api/kysfirms', kysfirm)
    }
  }
}
</script>
