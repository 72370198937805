export default {
  // main navigation - side menu
  columns: [
    {
      title: 'Reference',
      dataIndex: 'reference',
      width: '10%',
      sorter:  (a, b) => { return a.reference.localeCompare(b.reference)},
      key: 'reference',
      scopedSlots: { customRender: 'reference' },

    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter:  (a, b) => { return a.name.localeCompare(b.name)},
      key: 'name',
      width: '25%',
      scopedSlots: { customRender: 'name' },
//      fixed: 'left'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: '5%',
      scopedSlots: { customRender: 'country' },
    },
    {
      title: 'Usecases',
      dataIndex: 'usecases',
      scopedSlots: { customRender: 'usecases' },
//      fixed: 'right'
    },
  ]
}
