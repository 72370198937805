<template>
  <section>
    <div v-if="profile && profile.name">
      <a-timeline>
        <a-timeline-item style="padding-bottom:2px">
          <a-icon slot="dot" theme="filled" type="linkedin" style="font-size: 16px;" />
          <div class="strong">
            <a target="_blank" :href="profile.link || profile.flagshipProfileUrl">{{ profile.name }}</a>
          </div>
          <div>{{ profile.headline }}</div>
          <div>{{ profile.locationName }}</div>
        </a-timeline-item>
        <a-timeline-item v-if="profile.industry" style="padding-bottom:10px">
          <a-icon slot="dot"  type="info-circle" style="font-size: 16px;" />
          {{ profile.industry }}
        </a-timeline-item>
        <a-timeline-item v-for="(position,idx) in profile.positions" v-show="idx == 0 || show_all">
          <div class="strong"><a @click="configureKysFirm(position.companyName )">{{ position.companyName }}</a> <a target="_blank" :href="getLinkedinCompanyUrl(position.companyUrn)" v-if="position.companyUrn">
            <a-icon slot="dot" theme="filled" type="linkedin" style="font-size: 16px;" />
          </a>
            <KysFirmInfo :name="position.companyName" :kysfirms="kysfirms"/>
          </div>
          <div v-if="position.company">
            <span class="industry" v-if="position.company.industries" v-for="industry in position.company.industries">{{ industry}}</span>
          </div>
          <div>{{ position.title }}</div>
        </a-timeline-item>
        <a-timeline-item v-if="profile.positions.length > 1" style="padding-bottom:0px;">
          <a-button type="dashed" size="small" @click="show_all = !show_all">
            {{ show_all ? 'hide':'show' }} {{ profile.positions.length -1 }}
          </a-button>
        </a-timeline-item>
      </a-timeline>
    </div>

  </section>
</template>
<style>
  .strong {
    color:black;
    font-weight:500;
    font-size:13px;
  }

  .industry {
    font-style: italic;
  }
</style>
<script>
import KysFirmInfo from '@/components/kysfirm/KysFirmInfo.vue'

export default {
  name: 'LinkedIn',
  components: {
    KysFirmInfo
  },
  data: ()=>{
    return {
      show_all: false
    }
  },
  props: {
    profile: Object,
    kysfirms: Array
  },
  methods: {
    configureKysFirm: function(companyName) {
      this.$emit('kysfirm', companyName)
    },
    getLinkedinCompanyUrl: function(urn) {
      let urnParts = urn.split(":")
      return "https://linkedin.com/company/" + urnParts[urnParts.length-1]
    }
  }
}
</script>
